
import axios from "axios";
// import { showToast  } from 'vant';
import 'vant/lib/index.css';
import { showToast } from 'vant';
import { getLang } from '@/utils/util'
// 创建一个 axios 实例
const service = axios.create({
  baseURL: "/", // 所有的请求地址前缀部分
  timeout: 20000, // 请求超时时间毫秒
  // withCredentials: true, // 异步请求携带cookie
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    const dataAxios = response.data;
    return dataAxios;
  },
  function (error) {
    console.log(error);
    const lang = getLang()
    const langMap = {
      'jaJP': '接続がタイムアウトしました ページを更新するか、しばらく経ってから再度お試しください',
      'en': 'Network timeout. Please refresh the page or try again later',
      'thTH': 'เชื่อมต่อเครือข่ายหมดเวลา โปรดรีเฟรชหน้านี้หรือลองอีกครั้งในภายหลัง',
      'zhTW': '連接逾時 請更新頁面或稍後再試',
      'viVN':'Hết thời gian chờ mạng, vui lòng làm mới trang hoặc thử lại sau'
    }
    showToast(langMap[lang])
    return Promise.reject(error);
  }
);

export default service;
