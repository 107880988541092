import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-08fe0508"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderContainer = _resolveComponent("HeaderContainer");
  const _component_LoginPage = _resolveComponent("LoginPage");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_HeaderContainer, {
    onShowLogin: $setup.showLogins,
    token: $setup.token,
    class: "header-fix"
  }, null, 8, ["onShowLogin", "token"]), _createVNode(_component_van_popup, {
    show: $setup.show,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $setup.show = $event),
    onClose: _cache[1] || (_cache[1] = $event => $setup.listenClose())
  }, {
    default: _withCtx(() => [_createVNode(_component_LoginPage, {
      onCloseLoginContainer: $setup.closeLoginContainer,
      onSendToken: $setup.sendToken
    }, null, 8, ["onCloseLoginContainer", "onSendToken"])]),
    _: 1
  }, 8, ["show"])]), _createVNode(_component_router_view)], 64);
}