import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import { gameLogin } from '@/api/api';
import { showToast, Checkbox, CheckboxGroup } from 'vant';
import 'vant/lib/index.css';
import bus from '@/lib/bus';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { getLang, getGameId } from '@/utils/util';
export default {
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  setup(props, context) {
    const route = useRouter();
    const lang = ref(getLang());
    bus.$on('changeLang', () => {
      lang.value = getLang();
    });
    // tabs序号
    const loginActive = ref(0);
    // 关闭弹窗
    const closeLoginContainer = () => {
      context.emit('closeLoginContainer');
      clearInput();
    };
    const clearInput = () => {
      userPw.value = '';
      inputUserId.value = '';
    };
    // 账号登录
    const inputUserId = ref('');
    const userPw = ref('');
    const {
      t
    } = useI18n();
    const gameLogins = () => {
      if (!inputUserId.value || !userPw.value) {
        return showToast(t('邮箱信息填写错误'));
      }
      let prams = {
        game_id: getGameId(),
        account: inputUserId.value,
        password: userPw.value,
        official_setting_id: localStorage.getItem('official_setting_id') || 0
      };
      gameLogin(prams).then(res => {
        console.log(res);
        if (res.code != 200) {
          return showToast(res.message);
        }
        clearInput();
        checked.value = false;
        localStorage.setItem('token', res.data.access_token);
        localStorage.setItem('mobile', res.data.account);
        // bus.$emit('mobile', res.data.mobile)
        showToast(t('登录成功'));
        context.emit('sendToken', res.data.access_token);
        bus.$emit('login', res.data);
      });
    };
    const disabled = ref(false);
    const phoneInput = ref('');
    // 登录按钮点击
    const LoginBtn = () => {
      if (!checked.value) {
        showToast(t('请先同意下方《青瓷游戏隐私政策》、《青瓷游戏用户服务协议》'));
        return;
      }
      if (loginActive.value == 0) {
        gameLogins();
      }
    };
    // 协议确认
    const checked = ref(false);
    const goYhxy = () => {
      const url = route.resolve({
        path: './serviceaccount'
      });
      window.open(url.href);
    };

    // 用户服务协议
    const goAgreement = type => {
      const obj = {
        agreement: './serviceagreement',
        account: './serviceaccount',
        commerciallaw: './commerciallaw',
        ecommerciallaw: './economiclaw'
      };
      const url = route.resolve({
        path: obj[type]
      });
      window.open(url.href);
    };
    const kfBtn = () => {
      bus.$emit('showKf');
      closeLoginContainer();
    };
    const toGuide = () => {
      const officialSettingId = localStorage.getItem('official_setting_id');
      let routeOptions = {
        path: './guidemail'
      };
      if (officialSettingId) {
        routeOptions.query = {
          id: officialSettingId
        };
      }
      route.push(routeOptions);
    };
    const toForgot = () => {
      const officialSettingId = localStorage.getItem('official_setting_id');
      let routeOptions = {
        path: './forgotpassword'
      };
      if (officialSettingId) {
        routeOptions.query = {
          id: officialSettingId
        };
      }
      route.push(routeOptions);
    };
    const pwType = ref('password');
    // 设置隐私政策
    const goPolicy = type => {
      const urlMap = {
        en: localStorage.getItem('official_setting_id') == 5 ? 'https://law.qingcigame.com/sea-en/privacy_policy.html' : 'https://law.qingcigame.com/en-us/privacy_policy.html',
        thTH: 'https://law.qingcigame.com/th-th/privacy_policy.html',
        zhTW: 'https://law.snell.com.tw/zh-tw/privacy_policy.html',
        jp: 'https://law.qingcigame.com/ja-jp/privacy_policy.html',
        viVN: 'https://law.supersnail.vn/vi-vn/privacy_policy_web.html',
        idID: 'https://law.qingcigame.com/sea-id/privacy_policy.html',
        zhCN: 'https://law.qingcigame.com/sea-zh/privacy_policy.html'
      };
      window.open(urlMap[type], '_blank');
    };
    // 设置用户协议
    const goUser = type => {
      const urlMap = {
        en: localStorage.getItem('official_setting_id') == 5 ? 'https://law.qingcigame.com/sea-en/service_agreement.html' : 'https://law.qingcigame.com/en-us/service_agreement.html',
        thTH: 'https://law.qingcigame.com/th-th/service_agreement.html',
        zhTW: 'https://law.snell.com.tw/zh-tw/service_agreement.html',
        viVN: 'https://law.supersnail.vn/vi-vn/service_agreement_web.html',
        idID: 'https://law.qingcigame.com/sea-id/service_agreement.html',
        zhCN: 'https://law.qingcigame.com/sea-en/service_agreement.html'
      };
      window.open(urlMap[type], '_blank');
    };
    const toLogin = e => {
      if (e.keyCode === 13) {
        console.log(e);
        this.LoginBtn();
      }
    };
    return {
      pwType,
      kfBtn,
      clearInput,
      goYhxy,
      disabled,
      phoneInput,
      closeLoginContainer,
      gameLogins,
      inputUserId,
      userPw,
      LoginBtn,
      loginActive,
      checked,
      toGuide,
      lang,
      goPolicy,
      goUser,
      goAgreement,
      toLogin,
      toForgot
    };
  }
};