import { createRouter, createWebHistory } from "vue-router"
const routes = [
  { path: "/", component: () => import("../views/PayPage.vue") },
  {
    path: "/pay",
    name: "paypage",
    component: () => import("../views/PayPage.vue"),
  },
  {
    path: "/homepage",
    name: "homepage",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/pay:id",
    name: "paypagesec",
    component: () => import("../views/PayPage.vue"),
  },
  // 获取邮箱引导
  {
    path: "/guidemail",
    name: "guidemail",
    component: () => import("../components/guideMail/guideMail.vue"),
  },
  //列表页
  {
    path: "/orderpage",
    name: "orderpage",
    component: () => import("../views/OrderPage.vue"),
  },
  // 跳转中转页
  {
    path: "/gamepaypage",
    name: "gamepaypage",
    component: () => import("../views/GamePayPage.vue"),
  },
  // 跳转协议
  {
    path: "/serviceagreement",
    name: "serviceagreement",
    component: () => import("../views/ServiceAgreement.vue"),
  },
  // 跳转账号协议
  {
    path: "/serviceaccount",
    name: "serviceaccount",
    component: () => import("../views/ServiceAccount.vue"),
  },
  // 跳转资金协议
  {
    path: "/commerciallaw",
    name: "commerciallaw",
    component: () => import("../views/CommercialLaw.vue"),
  },
  // 跳转资金协议
  {
    path: "/economiclaw",
    name: "economiclaw",
    component: () => import("../views/EConomicLaw.vue"),
  },
  // 忘记密码
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () => import("../components/ForgotPassword/ForgotPassword.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
