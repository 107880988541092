import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "element-plus/theme-chalk/index.css"
import "@vant/touch-emulator"
import i18n from "./lang/i18n"
import { setLang } from "@/utils/util"
import ElementPlus from "element-plus"
// import eruda from "eruda";
// import "amfe-flexible"; //引入px自动转化rem工具
createApp(App).use(router).use(i18n).use(ElementPlus).mount("#app")

const currentUrl = new URL(window.location.href)
const params = new URLSearchParams(currentUrl.search)
const paramName = params.get("id") //东南亚混服专用id
const lang = params.get("lang") //混服语言，默认为英文
if (paramName) {
  localStorage.setItem("official_setting_id", paramName)
  if (lang) {
    localStorage.setItem("lang", lang)
  }
} else {
  localStorage.removeItem("official_setting_id")
  localStorage.removeItem("lang")
}
setLang()
