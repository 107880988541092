import "core-js/modules/es.array.push.js";
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Dialog } from 'vant';
import 'vant/lib/index.css';
import bus from '@/lib/bus';
import { getLang } from '@/utils/util';
import { useI18n } from 'vue-i18n';
export default {
  props: ['token'],
  components: {
    [Dialog.name]: Dialog
  },
  setup(props, context) {
    const lang = ref(getLang());
    const isTabbar = ref(1);
    const type = ref(1); //1未登录2已登录
    const showList = ref(false);
    const mobile = ref('');
    const showTabrList = () => {
      showList.value = !showList.value;
    };
    const showLogin = () => {
      if (type.value == 2) {
        localStorage.removeItem('token');
        type.value = 1;
        localStorage.removeItem('mobile');
        mobile.value = '';
        bus.$emit('clear');
        return;
      }
      context.emit('showLogin');
    };
    // 登录状态
    const tokenType = props;
    watch(tokenType, newValue => {
      if (newValue) {
        type.value = 2;
        mobile.value = localStorage.getItem('mobile');
      }
    });
    // 前往官方充值页面
    const route = useRouter();
    const goPayPage = () => {
      showList.value = false;
      isTabbar.value = 1;
      const officialSettingId = localStorage.getItem('official_setting_id');
      let routeOptions = {
        path: lang.value == 'zhTW' ? './homepage' : './pay'
      };
      if (officialSettingId) {
        routeOptions.query = {
          id: officialSettingId
        };
      }
      route.push(routeOptions);
    };

    // 前往订单页面
    const goOrderPage = () => {
      showList.value = false;
      if (!localStorage.getItem('token')) {
        bus.$emit('click');
        return;
      }
      isTabbar.value = 2;
      const officialSettingId = localStorage.getItem('official_setting_id');
      let routeOptions = {
        path: './orderpage'
      };
      if (officialSettingId) {
        routeOptions.query = {
          id: officialSettingId
        };
      }
      route.push(routeOptions);
    };
    const goHomePage = () => {
      isTabbar.value = 1;
      const officialSettingId = localStorage.getItem('official_setting_id');
      let routeOptions = {
        path: lang.value == 'zhTW' ? './homepage' : './pay'
      };
      if (officialSettingId) {
        routeOptions.query = {
          id: officialSettingId
        };
      }
      route.push(routeOptions);
    };
    const goJfSC = () => {
      showList.value = false;
      isTabbar.value = 3;
      if (!localStorage.getItem('token')) {
        bus.$emit('click');
        return;
      }
      route.push({
        path: './pointmall'
      });
    };
    bus.$on('changeTab', e => {
      isTabbar.value = e;
    });
    const goKf = () => {
      const kfMap = {
        en: 'https://sdk-sg.qcplay.com/contact?game_sdk_id=19&language=en-us',
        jaJP: 'https://snail.qcplay.co.jp/contact.html'
      };
      const lang = localStorage.getItem('lang') || 'jaJP';
      showList.value = false;
      if (lang === 'thTH' || lang === 'viVN') {
        window.AIHelpSupport.show();
      } else if (lang === 'en' && localStorage.getItem('official_setting_id') == 5) {
        window.AIHelpSupport.show();
      } else {
        // window.location.href = kfMap[lang]
        window.open(kfMap[lang]);
      }
    };
    bus.$on('showKf', () => {
      goKf();
    });
    const officialSettingId = ref(''); //获取url传参判断是否为混服
    onMounted(() => {
      officialSettingId.value = localStorage.getItem('official_setting_id');
      if (localStorage.getItem('token')) {
        type.value = 2;
        mobile.value = localStorage.getItem('mobile');
      }
    });
    bus.$on('loginOut', () => {
      showLogin();
    });
    // 切换语言
    bus.$on('webLangChange', e => {
      handleCommand(e);
    });
    const {
      locale
    } = useI18n();
    const handleCommand = command => {
      locale.value = command;
      localStorage.setItem('lang', command);
      isTabbar.value = 1;
      let routeOptions = {
        path: './pay'
      };
      routeOptions.query = {
        id: 5,
        lang: command
      };
      route.push(routeOptions);
      bus.$emit('login');
      const titleMap = {
        en: 'QCPlay | QCPLAY PAYMENT CENTER',
        jaJP: 'QCPlay | QCPlayトークンストア',
        thTH: 'QCPlay | ศูนย์เติมเงินทางการของ QCPLAY',
        zhTW: 'Snell Game官方儲值中心',
        viVN: 'Trang nạp tiền chính thức của VISION NETWORK',
        zhCN: '青瓷游戏官方充值中心',
        idID: 'Pusat Top Up Resmi QCPlay'
      };
      document.title = titleMap[command];
    };
    return {
      officialSettingId,
      handleCommand,
      goHomePage,
      mobile,
      isTabbar,
      goKf,
      goOrderPage,
      goPayPage,
      type,
      showList,
      showTabrList,
      showLogin,
      goJfSC,
      lang
    };
  }
};