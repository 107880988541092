import en from './en.json'
import zhCN from './zh-CN.json'
import jaJP from "./ja-JP.json"
import thTH from "./th-TH.json"
import zhTW from "./zh-TW.json"
import viVN from "./vi-VN.json"
import idID from "./id-ID.json"
export default {
  en,
  zhCN,
  jaJP,
  thTH,
  zhTW,
  viVN,
  idID
}